import React from 'react';

const Notfound = () => {
  return (
    <div>
      Sorry book not found
    </div>
  );
}

export default Notfound;
